import React from "react"
import Turnstile from "react-turnstile"

const Captcha = ({ setIsCaptchaNotSolved }) => {
  return (
    <div className="columns is-centered">
      <Turnstile
        sitekey={process.env.GATSBY_CLOUDFLARE_TURNSTILE_CAPTCHA_SITE_KEY}
        onSuccess={setIsCaptchaNotSolved(false)}
      />
    </div>
  )
}

export default Captcha
