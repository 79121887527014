import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Layout from "layout/Layout"
import Container from "layout/Container"
import styles from "./utils/staticPages.module.scss"

const UnderMaintenance = () => {
  const data = useStaticQuery(graphql`
    {
      spilledMeds: file(relativePath: { eq: "pages/404__spilledMeds.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const spilledMeds = data.spilledMeds.childImageSharp.fluid

  return (
    <Layout
      seoTitle="Not Found"
      display={{ helpCenterBanner: false }}
      removeLinks={process.env.GATSBY_HAS_SPECIAL_ORDER_ENABLED === "disabled"}
    >
      <div className={classNames(styles["underMaintenance"])}>
        <Container customClassName="my-3" isCentered>
          <Container mobile={10} tablet={8} desktop={6} isCentered>
            <Img fluid={spilledMeds} />
          </Container>
          <center>
            <h2>
              We're upgrading our systems to better improve your experience.
            </h2>
            <p className="is-size-4">
              For inquiries, please send an email to{" "}
              <a
                href="mailto:careplus@medgrocer.com"
                className="has-text-weight-bold"
              >
                careplus@medgrocer.com
              </a>
              .
            </p>
          </center>
        </Container>
      </div>
    </Layout>
  )
}

export default UnderMaintenance
