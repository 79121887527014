import { isBrowser } from "../general"
import axios from "axios"

export const firebaseApi = ({ accessToken }) => {
  if (isBrowser()) {
    let api = axios.create({
      baseURL: `https://firestore.googleapis.com/v1/projects/${process.env.GATSBY_FIREBASE_PROJECT_ID}/databases/(default)/documents/`,
      headers: {
        Authorization: "Bearer " + (accessToken || ""),
        "Content-Type": "application/json",
      },
    })
    return api
  }
  return null
}
