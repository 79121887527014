import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"

/**
 * @param {string} title string
 * @param {} children
 */

const Section = ({ title, children, addOns, className, id, subtitle }) => (
  <section id={id || ""}>
    {title && (
      <div className={classNames(styles["section"])}>
        {addOns?.left}
        <h4 className="has-text-primary mt-0">
          {title}
          {subtitle && (
            <p className="has-text-weight-normal has-text-grey is-size-6 mt-1">
              {subtitle}
            </p>
          )}
        </h4>
        {addOns?.right}
      </div>
    )}
    <div className={className || "mt-1 mb-3"}>{children}</div>
  </section>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Section
